import React from 'react';
import Responsive from 'react-responsive';
import PropTypes from 'prop-types';
import { minLG } from './variables';

const MinLG = ({ children }) => (
  <Responsive minWidth={minLG}>
    {children}
  </Responsive>
);

MinLG.propTypes = {
  children: PropTypes.node,
};

MinLG.defaultProps = {
  children: null,
};

export default MinLG;
