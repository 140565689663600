// creen sizes
const maxXXL = 1599.98;
const maxXL = 1199.98;
const maxLG = 991.98;
const maxMD = 767.98;
const maxSM = 575.98;
const maxXS = 374.98;

const minXXL = 1600;
const minXL = 1200;
const minLG = 992;
const minMD = 768;
const minSM = 576;
const minXS = 375;

export {
  maxXXL,
  maxXL,
  maxLG,
  maxMD,
  maxSM,
  maxXS,
  minXXL,
  minXL,
  minLG,
  minMD,
  minSM,
  minXS,
};
