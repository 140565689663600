import React from 'react';
import Responsive from 'react-responsive';
import PropTypes from 'prop-types';
import { maxLG } from './variables';

const MaxLG = ({ children }) => (
  <Responsive maxWidth={maxLG}>
    {children}
  </Responsive>
);

MaxLG.propTypes = {
  children: PropTypes.node,
};

MaxLG.defaultProps = {
  children: null,
};

export default MaxLG;
