import React from 'react';
import { Link } from 'gatsby';

import logo from './img/logo.svg';
import facebook from './img/facebook.svg';
import linkedin from './img/linkedin.svg';
import instagram from './img/instagram.svg';
// import upwork from './img/upwork.svg';
// import tikTok from './img/tikTok.svg';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__list">

        <div className="Footer__item">
          <Link
            to="/"
            title="Logo"
          >
            <img
              className="Footer__logo"
              src={logo}
              alt="SolidOne"
            />
          </Link>
          <p>Software Development Company</p>
          <p>&copy; SolidOne Software OÜ, 2022-2023</p>
        </div>

        <div className="Footer__item">
          <address className="Footer__social">
            {/* <a
              title="TikTok"
              href="https://tiktok.com/@solidoneteam"
              target="_blank"
              rel="noreferrer"
            >TikTok</a> */}
            <a
              title="facebook"
              href="https://www.facebook.com/SolidOneTeam"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={facebook}
                alt="SolidOne facebook"
              />
            </a>
            <a
              title="linkedin"
              href="https://www.linkedin.com/company/solidoneteam"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={linkedin}
                alt="SolidOne linkedin"
              />
            </a>
            <a
              title="Instagram"
              href="https://www.instagram.com/solidoneteam"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={instagram}
                alt="SolidOne instagram"
              />
            </a>
            {/* <a
              title="upwork"
              href="https://www.upwork.com/ag/solidoneteam"
              target="_blank"
              rel="noreferrer"
            >upwork</a> */}
          </address>
        </div>
      </div>
    </footer>
  );
};

export default Footer;